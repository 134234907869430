import React from "react";

export default ({ id, activo, company, description, discount, title, places, web, is_maquinero, logo,  ...props }) => {

  return (
    <div className="beneficio-card" >
      <div className="beneficio-card__avatar">
        <i className="beneficio-card__avatar__notification-icon icon--circle hidden"></i>
        {logo
          ? <div className="beneficio-card__avatar__image" src={logo} style={{backgroundImage: `url(${logo})`}} alt="" />
          : <div className="beneficio-card__avatar__image">
              <span className="beneficio-card__avatar__text">
                {title ? title[0] : ""}
              </span>
            </div>
        }
      </div>
      <h1 className="beneficio-card__nombre">
        {company}
      </h1>
      <p className="beneficio-card__titulo">
        {title}
      </p>
      {discount &&
        <p className="beneficio-card__descuento">
          {discount}
        </p>}
    </div>
  );
};
