import { Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, Input, InputLabel, Link, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import APIManager from '../../lib/apiManager';
import EditorTest from "../EditorTest";
import WYSIWYGEditor from "../WYSIWYGEditor";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { Stack } from '@mui/material';
import { InsertDriveFile } from "@mui/icons-material";
import { sistema } from '../../model/Sistema';
import BeneficioCard from "./components/BeneficioCard";

const Beneficio = ({setBotonera, espacios}) => {

  const [beneficio, setBeneficio] = useState(null);
  const [active, setActive] = useState(false);

  const [editando, setEditando] = useState(false);

  const [espacio, setEspacio] = useState(null);

  const [empresa, setEmpresa] = useState('');
  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [web, setWeb] = useState('');

  const [prestadores, setPrestadores] = useState([]);
  const [prestador, setPrestador] = useState(null);
  const [prestadorNombre, setPrestadorNombre] = useState('');

  const [habilitadoParaGuardar, setHabilitadoParaGuardar] = useState(false);

  const [ready, setReady] = useState(false);

  let { beneficioId } = useParams();

  const nuevoBeneficio = {
    id: null,
    empresa: '',
    titulo: '',
    descripcion: '',
    descuento: '',
    web: '',
    prestador: null,
    active: false,
  };

  const botoneraDeBeneficio = [
    { title: 'Volver', label: 'Volver', variant: 'outlined', onClick: () => { navigate(-1); } },
    { title: 'Editar', label: 'Editar', variant: (editando ? 'outlined' : 'contained'), visible: !editando, onClick: () => { setEditando(!editando); } },
    { title: 'Guardar', label: 'Guardar', variant: 'contained', visible: editando, disabled: !habilitadoParaGuardar, onClick: () => { handleGuardar(); } },
  ];

  const navigate = useNavigate();

  const editarBeneficio = () => {
    setEditando(!editando);
  }

  const beneficioActualizado = () => {
    const miBeneficioActualizado = { ...beneficio };
    miBeneficioActualizado.empresa = empresa;
    miBeneficioActualizado.prestador = prestador;
    miBeneficioActualizado.titulo = titulo;
    // miBeneficioActualizado.descripcion = descripcion;
    // miBeneficioActualizado.descuento = descuento;
    miBeneficioActualizado.web = web;
    miBeneficioActualizado.active = active;

    return miBeneficioActualizado;

  }

  const guardarBeneficio = () => {
    const beneficioAEnviar = beneficioActualizado();

    APIManager.sendRequest('post', 'beneficios', 'modificarBeneficio', { beneficio: beneficioAEnviar }, (response) => {
      if (response.success) {
        setEditando(false); // Se guardó y ya no estamos editando
        setBeneficio(response.data.beneficio);
      }
    });

  }

  // const handleTipoDeBeneficio = (event) => {
  //   const tipo = event.target.value;
  //   setTipoDeBeneficioNombre(tipo.descripcion);
  //   setTipoDeBeneficio(tipo);
  //   setHabilitadoParaGuardar(true);
  // }

  const handleActive = (event) => {
    setActive(event.target.checked);
    setHabilitadoParaGuardar(true);
  }

  // const handleReferente = (referenteActualizado) => {
  //   if (!!referenteActualizado.usuarioPrincipal === !!referente.usuarioPrincipal) {
  //     setReferente(referenteActualizado);
  //     setHabilitadoParaGuardar(true);
  //   }
  // }

  // const handleEspacio = (espacioActual) => {
  //   setEspacio(espacioActual);
  //   setHabilitadoParaGuardar(true);
  // }

  // const handleMembresia = (membresiaActual) => {
  //   setMembresia(membresiaActual);
  //   setHabilitadoParaGuardar(true);
  // }

  // const handleRazonSocial = (event) => {
  //   setRazonSocial(event.target.value);
  //   setHabilitadoParaGuardar(true);
  // }

  // const handleCuit = (event) => {
  //   setCuit(event.target.value);
  //   setHabilitadoParaGuardar(true);
  // }

  const handleWeb = (event) => {
    setWeb(event.target.value);
    setHabilitadoParaGuardar(true);
  }

  // const handleEmail = (event) => {
  //   setEmail(event.target.value);
  //   setHabilitadoParaGuardar(true);
  // }

  const handleGuardar = (event) => {
    guardarBeneficio();
  }

  // const handleChangeTipoDeBeneficio = () => {
  //   setReady(false);
  //   let nuevoBeneficio = null;
  //   nuevoBeneficio = beneficioFrom(beneficio, tipoDeBeneficio, referente);
  //   setBeneficio(nuevoBeneficio);
  // }

  useEffect(() => {

    if (!!beneficioId) {
      if (beneficioId === "nuevo") {
        // Es uno nuevo
        const miNuevoBeneficio = Object.assign({}, nuevoBeneficio);
        setBeneficio(miNuevoBeneficio);
        setEditando(true);
      } else {
        // Vino beneficioId, obtenemos el beneficio
        const postData = {};
        postData.id = beneficioId;

        APIManager.sendRequest('post', 'beneficios', 'getBeneficio', postData, (response) => {
          if (response.success) {
            setBeneficio(response.data.beneficio);
          }
        });
      }

      // APIManager.sendRequest('post','ui','getTiposDeBeneficio', {}, (response) => {
      //   if (response.success) {
      //     setTiposDeBeneficio(response.data);
      //   }
      // })
    }

  }, [beneficioId]);

  useEffect(() => {
    if (!!beneficio) {
      sistema.actual.beneficio = beneficio;
      setEmpresa(beneficio.empresa);
      setTitulo(beneficio.titulo ?? beneficio.nombre);
      setWeb(beneficio.web);
      setWeb(beneficio.web);
      setActive(beneficio.active);
      // const nuevoEspacio = espacios.find((item) => {
      //   if (!!beneficio.espacio) {
      //     return item.id === beneficio.espacio.id;
      //   } else {
      //     return item.id === sistema.espacio.id;
      //   }
      // });
      // setEspacio(nuevoEspacio);
    }
  }, [beneficio]);
/*
  useEffect(() => {
    if (!!beneficio && (!!membresias.length > 0)) {
      let membresiaID;
      if (!!beneficio.membresia) {
        membresiaID = beneficio.membresia.id;
      } else {
        membresiaID = membresias[0].id;
      }
      setMembresia(membresias.find((item) => { return item.id === membresiaID }));
    }
  }, [beneficio, membresias]);
 */
/*
  useEffect(() => {
    // console.log('useEffect: referente');
    if (!!referente) {
      if (beneficio.tipo === 'EMPRESA') {
        // console.log('Empresa:', referente);
        setEmpresa(referente);
        if (!!referente.usuarioPrincipal) {
          setMiembro(referente.usuarioPrincipal);
        }
        if (!email && !!referente.usuarioPrincipal) {
          setEmail(referente.usuarioPrincipal.emailDeContacto);
        }
      } else {
        // console.log('Miembro:', referente);
        setMiembro(referente);
        if (!email && !!referente) {
          setEmail(referente.emailDeContacto);
        }
      }
    }
    setReady(true);
  }, [referente]);
 */
/*
  useEffect(() => {
    if (!tipoDeBeneficio) {
      if (!!beneficio && (tiposDeBeneficio.length > 0)) {
        setTipoDeBeneficio(tiposDeBeneficio.find(item => item.tipo === beneficio.tipo));
      }
    }
  }, [beneficio, tiposDeBeneficio]);
 */
/*
  useEffect(() => {
    if (!!tipoDeBeneficio && (tipoDeBeneficio.tipo !== beneficio.tipo)) {
      handleChangeTipoDeBeneficio();
    }
  }, [tipoDeBeneficio]);
 */
  useEffect(() => {
    setBotonera(botoneraDeBeneficio);
  }, [habilitadoParaGuardar, titulo, active, editando, empresa, descripcion, web]);

  useEffect(() => {
    Window.sistema = sistema;
  }, []);

  return (
    <Paper sx={{ padding: '14px' }}>
      {!!beneficio
        ? <Grid container spacing={2}>
          {/* <Grid item xs={12} sm={2} >
            <FormControl variant="standard">
              <InputLabel id="tipoDeBeneficio-label" shrink={true} >Tipo de Beneficio</InputLabel>
              <Select
                // labelId="tipoDeBeneficio-label"
                id="tipoDeBeneficio-select"
                value={tipoDeBeneficio}
                onChange={handleTipoDeBeneficio}
                label="Tipo de Beneficio"
                disabled={!editando && (beneficioId !== 'nuevo')}
              >
                {tiposDeBeneficio.map(
                  (tipo => <MenuItem value={tipo}>{tipo.descripcion}</MenuItem>)
                )}
              </Select>
            </FormControl>
          </Grid> */}
          {/* <Grid item xs={12} sm={4}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="razonSocial-label" >Razon Social</InputLabel>
              <Input
                disabled={!editando}
                labelId="razonSocial-label"
                id="razonSocial-input"
                value={razonSocial}
                onChange={handleRazonSocial}
                required
              >
              </Input>
            </FormControl>
          </Grid> */}
          {/* <Grid item xs={12} sm={2}>
            <FormControl variant="standard">
              <InputLabel id="cuit-label" >CUIT</InputLabel>
              <Input
                disabled={!editando}
                labelId="cuit-label"
                id="cuit-input"
                value={cuit}
                onChange={handleCuit}
                required
              >
              </Input>
            </FormControl>
          </Grid> */}
          <Grid item xs={12} sm={2} >
            <FormControlLabel label="Activo" control={
              <Checkbox disabled={!editando} checked={active} onChange={handleActive} />
            } />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl variant="standard">
              <InputLabel id="id-label" >ID</InputLabel>
              <Input
                disabled={true}
                labelId="id-label"
                id="id-input"
                value={((!!beneficio && !!beneficio.id) ? beneficio.id : '') + (!!beneficio && !!beneficio.id && !!beneficio.beneficio_id ? ' :: ' : '') + (!!beneficio && !!beneficio.beneficio_id ? beneficio.beneficio_id : '')}
              >
              </Input>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="cuit-label" >WEB</InputLabel>
              <Input
                disabled={!editando}
                labelId="web-label"
                id="web-input"
                value={web}
                onChange={handleWeb}
                required
              >
              </Input>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="email-label" >Email de contacto</InputLabel>
              <Input
                disabled={!editando}
                labelId="email-label"
                id="email-input"
                value={email}
                onChange={handleEmail}
                required
              >
              </Input>
            </FormControl>
          </Grid> */}
          <Grid item xs={12}>
            <BeneficioCard {...beneficio} />
          </Grid>
        </Grid>
        : <>{ 'No hay datos del beneficio' }</>
      }
    </Paper>
  )
}

export default Beneficio;