import React from 'react';

import logo from '../../mimqco--logo.svg';
import './styles.css';

// import { useTranslate } from '@tolgee/react';

export const SplashScreen = ({ version }) => {
  // const { t } = useTranslate();

  return (
    <div className="Splash">
      <img src={logo} className="Splash-logo" alt="logo" />
      <span>{'v'+version}</span>
      {/* <span>{'v'+version}{t('splashVersion', ' es la version')}</span> */}
    </div>
  )

}

export default SplashScreen;