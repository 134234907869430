import { Box, Checkbox, Dialog, DialogContent, DialogTitle, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import APIManager from '../../lib/apiManager';
import { sistema } from "../../model/Sistema";
import NuevoModal from "./components/NuevoModal";
import { CheckCircleOutlined } from "@mui/icons-material";

const Nacionalidades = ({setBotonera}) => {

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('nombre');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);

  const [successOpen, setSuccessOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const [nombre, setNombre] = useState('');

  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const columns = [
    {
      id: 'nombre',
      numeric: false,
      disablePadding: false,
      label: 'Nombre',
    },
    {
      id: 'descripcion',
      numeric: false,
      disablePadding: false,
      label: 'Descripción',
    },
  ];

  const isSelected = (name) => selected.indexOf(name) !== -1;

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const actualizarItems = () => {
    setRows([]);
    APIManager.getList('nacionalidades', {orderBy: 'nombre'}, (response) => {
      if (response.success) {
        setRows(response.data.listado);
      }
    })
  }

  const handleChangeNombre = (event) => {
    setNombre(event.target.value);
  }

  const handleNuevoItem = () => {
    sistema.actual = { item: null };
    setSelectedItem(null);
    setModalOpen(true);
  }

  const handleEditarItem = (item) => {
    sistema.actual = { item: item };
    setSelectedItem(item);
    setModalOpen(true);
  }

  const editarItem = (item) => {
    APIManager.sendRequest('post', 'sistema', 'modificarItem', { tipo: 'nacionalidades', item }, (response) => {
      if (response.success) {
        // Cliente excluido correctamente!
        actualizarItems();
        setSuccessOpen(true);
      }
    });
  }

  useEffect(() => {
    setBotonera([
      { title: 'Agregar', label: 'Agregar', variant: 'outlined', onClick: handleNuevoItem },
      // { title: 'Editar', label: 'Editar', variant: 'outlined', onClick: handleEditarItem },
      // { title: 'Quitar', label: 'Quitar', variant: 'outlined', disabled: (selected.length === 0), onClick: handleRemoverExcluido },
      // { type: 'input', id: 'input-nombre', inputType: 'text', title: 'Nombre de cliente', label: 'Nombre', value: nombre, onChange: handleChangeNombre },
    ]);
  }, [selected, nombre, ]);


  useEffect(() => {
    if (!!successOpen) {
      setTimeout(() => {setSuccessOpen(false) }, 3000);
    }
  }, [successOpen])

  useEffect(() => {
    actualizarItems();
  }, []);

  return (
    <Box>
      <NuevoModal open={modalOpen} setOpen={setModalOpen} columns={columns} selectedItem={selectedItem} setSelectedItem={editarItem} />
      <Dialog open={successOpen} onClose={() => {setSuccessOpen(false)}} setOpen={setSuccessOpen}>
        <DialogContent sx={{ bgcolor: "green" }}>
          <DialogTitle sx={{color: "white"}}>Operación confirmada</DialogTitle>
          <Stack alignItems="center" justifyContent="center">
            <CheckCircleOutlined sx={{ color: "white" }} fontSize="large" />
          </Stack>
        </DialogContent>
      </Dialog>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Typography padding={2} fontWeight="bold" fontSize="large">Lista de Nacionalidades</Typography>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < rows.length}
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </TableCell> */}
                {columns.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    component="th"
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                  rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleEditarItem(row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, row.id)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell> */}
                      {columns.map((column, colIdx) =>
                        <TableCell
                          id={labelId}
                          align={column.numeric ? 'right' : 'left'}
                          padding={column.disablePadding ? 'none' : 'normal'}
                        >
                          {row[column.id]}
                        </TableCell>
                      )}
                      {/* <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.client_id}
                      </TableCell>
                      <TableCell align="left">{row.client_description}</TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
    </Box>
  );
}

export default Nacionalidades;